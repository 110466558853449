import {useGeneralApp} from "~/stores/generalApp.js";
import {useNotifications} from "~/stores/notifications.js";
import {navigateTo} from "#app";


export const useHandleSignIn = async () => {
    const config = useRuntimeConfig();
    const generalApp = useGeneralApp();
    const route = useRoute();
    try {
        generalApp.requestProcessing = true
        await fetch(`${config.public.apiBase}/auth/signin`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Accept": "application/json"
            },
            body: JSON.stringify({
                "phone": generalApp.userPhone
            })
        }).then(async (response) => {
            generalApp.lastGetCodeRequested = new Date().getTime()
            generalApp.requestProcessing = false
            if (route.path !== '/confirm') {
                let res = await response.json();
                generalApp.needPersonalData = res.Data.isFirstLogin
                return navigateTo('/confirm')
            }
        })
    } catch (e) {
        console.log(e, 'error during sign-in')
        const notifications = useNotifications();
        notifications.networkError = true
        generalApp.requestProcessing = false
        const networkError = document.getElementById('network-error-dialog')
        networkError.showModal();
    }
}